import {
  EAppType,
  EUserType,
  IDadosDispositivo,
  IUser,
  TPayloadNotification,
} from "@deltasge/marauders-map";
import { FirebaseError } from "firebase/app";
import {
  getToken,
  onMessage as onMessageFBM,
  isSupported as isSupportedFBM,
  Messaging,
} from "firebase/messaging";
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import axios, { AxiosResponse } from "axios";
import { useLocalStorage } from "react-use";

import {
  NotificationTypes,
  PushNotificationService,
  WindowWebKitIOS,
} from "interfaces";

import { Environment } from "configs";
import { showSnack, snack } from "components/GlobalSnackbar";
import { NotificationComponent } from "components/NotificationComponent";
import { useConfirmDialog } from "hooks/useDialogConfirm";
import { useAppDispatch, useAppSelector } from "store";
import { set as setUsuario } from "store/modules/auth/usuario";
import { getError, isWebKit } from "utils";

export const PushNotificationContext = createContext<PushNotificationService>({
  isSupported: false,
  permission: "default",
  requestPermission: () => {},
  requestPushToken: () => {},
});

export const usePushNotification = () => {
  return useContext(PushNotificationContext);
};

export const ProviderPushNotification: FC<
  PropsWithChildren<{
    fcm: Messaging;
  }>
> = ({ children, fcm }) => {
  const {
    usuario: { usuario },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const [dispositivo, setDispositivo] = useState<IDadosDispositivo>();
  const [permission, setPermission] = useLocalStorage<NotificationTypes>(
    "notification-permission",
    "default"
  );
  const [token, setToken] = useLocalStorage<string | undefined>(
    "notification-token",
    undefined
  );
  const [isSupported, setIsSupported] = useLocalStorage(
    "notification-isSupported",
    false
  );
  const [lastNotification, setLastNotification] = useLocalStorage<any>(
    "notification-last-notification",
    undefined
  );

  const registrarPushUsuario = useCallback(
    async (usuario: IUser, token: string) => {
      try {
        const codigoZeroEsqueda = parseInt(usuario.escolaId, 10)
          .toString()
          .padStart(4, "0");

        if (!usuario.id) {
          throw new Error("Erro ao encontrar o ID do usuário");
        }

        const idUsuario =
          usuario.tipoUsuario == EUserType.UsuarioSistema ? usuario.id : null;
        const idProfessor =
          usuario.tipoUsuario == EUserType.Professor ? usuario.id : null;

        const payload: Partial<IDadosDispositivo> = {
          idUsuario,
          idProfessor,
          app: EAppType.Educador,
        };

        const { data } = await axios.put<IDadosDispositivo>(
          `${Environment.API_URL}/api/${codigoZeroEsqueda}/dados-dispositivo/tipo-usuario/${usuario.tipoUsuario}/token/${token}`,
          payload,
          {
            headers: { Authorization: `Bearer ${usuario.token}` },
          }
        );
        setDispositivo(data);

        if (!usuario.pushTokens.find((f) => f == token)) {
          const pushTokens = usuario.pushTokens.concat(token);
          const { data }: AxiosResponse<IUser> = await axios.put(
            `${Environment.CONFIG_DELTA_API_URL}/usuarios/usuario/${usuario.deltaId}`,
            {
              ...usuario,
              pushTokens,
            },
            {
              headers: { Authorization: `Bearer ${usuario.token}` },
            }
          );
          dispatch(setUsuario({ ...data, pushTokens }));
        }
      } catch (error) {
        snack.error(getError(error));
      }
    },
    [usuario?.id, dispatch]
  );

  const setPermissionFromWebKit = useCallback(
    (evt: Event) => {
      try {
        const state = (evt as CustomEvent).detail;
        if (typeof state != "string") {
          setPermission("denied");
          return;
        }
        if (
          ["authorized", "ephemeral", "provisional", "granted"].includes(state)
        ) {
          setPermission("granted");
        } else if (["unknown", "notDetermined"].includes(state)) {
          setPermission("default");
        } else {
          setPermission("denied");
        }
      } catch (error) {
        setPermission("denied");
      }
    },
    [setPermission]
  );

  const requestPermissionFromWeb = useCallback(async () => {
    const permission = await Notification.requestPermission();
    setPermission(permission);
  }, [setPermission]);

  const requestPermissionFromWebKit = async () => {
    await (
      window as WindowWebKitIOS
    ).webkit.messageHandlers.pushPermissionRequest.postMessage(
      "pushPermissionRequest"
    );
  };

  const requestPermission = useCallback(() => {
    try {
      if (isWebKit) {
        requestPermissionFromWebKit();
      } else {
        requestPermissionFromWeb();
      }
    } catch (error) {
      snack.error(getError(error));
    }
  }, [requestPermissionFromWeb]);

  const setTokenFromWebKit = useCallback(
    (evt: Event) => {
      const token = (evt as CustomEvent).detail;
      if (typeof token == "string") setToken(token);
    },
    [setToken]
  );

  const requestPushTokenFromWebKit = async () => {
    await (
      window as WindowWebKitIOS
    ).webkit.messageHandlers.getPushToken.postMessage("getPushToken");
  };

  const requestPushTokenFromWeb = useCallback(async () => {
    try {
      const token = await getToken(fcm, {
        vapidKey: Environment.FCM_VAPIDKEY,
      });
      setToken(token);
    } catch (error) {
      if (
        !(error instanceof FirebaseError) ||
        error.code != "messaging/permission-blocked"
      ) {
        throw error;
      }
    }
  }, [fcm, setToken]);

  const requestPushToken = useCallback(() => {
    try {
      if (isWebKit) {
        requestPushTokenFromWebKit();
      } else {
        requestPushTokenFromWeb();
      }
    } catch (error) {
      snack.error(getError(error));
    }
  }, [requestPushTokenFromWeb]);

  const requestSupport = useCallback(async () => {
    try {
      if (isWebKit) {
        setIsSupported(
          (window as WindowWebKitIOS).webkit.messageHandlers != undefined
        );
      } else {
        setIsSupported(await isSupportedFBM());
      }
    } catch (error) {
      setIsSupported(false);
      snack.error(getError(error));
    }
  }, [setIsSupported]);

  const onMessageFromWebKit = useCallback(
    (evt: Event) => {
      try {
        const payload = (evt as CustomEvent).detail as Record<string, unknown>;
        setLastNotification(payload);
        if (
          typeof payload.titulo == "string" &&
          typeof payload.descricao == "string"
        ) {
          showSnack(
            <NotificationComponent
              payload={payload as TPayloadNotification}
              notification={{
                body: payload.descricao,
                title: payload.titulo,
                icon:
                  typeof payload.icon == "string" ? payload.icon : undefined,
                image:
                  typeof payload.image == "string" ? payload.image : undefined,
              }}
            />
          );
        }
      } catch (error) {
        snack.error(getError(error));
      }
    },
    [setLastNotification]
  );

  const onMessageFromWeb = () => {
    if (permission == "granted" && !isWebKit) {
      onMessageFBM(fcm, (payload) => {
        setLastNotification(payload);
        if (payload && payload.data) {
          const data = payload.data as TPayloadNotification;
          showSnack(
            <NotificationComponent
              payload={data}
              notification={{
                body: data.descricao,
                icon: data.icon || undefined,
                image: data.image || undefined,
                title: data.titulo,
              }}
            />
          );
        }
      });
    }
  };

  onMessageFromWeb();

  const { show, RenderDialog } = useConfirmDialog<boolean>({
    defaults: {
      title: "Notificações",
      content: "Deseja receber notificações?",
    },
    onConfirmed: (payload, hide) => {
      hide();
      requestPermission();
    },
    onCanceled: (hide) => {
      setPermission("denied");
      hide();
    },
  });

  useEffect(() => {
    requestSupport();
    window.addEventListener("pushPermissionRequest", setPermissionFromWebKit);
    window.addEventListener("pushPermissionState", setPermissionFromWebKit);
    window.addEventListener("pushToken", setTokenFromWebKit);
    window.addEventListener("pushNotification", onMessageFromWebKit);

    return () => {
      window.removeEventListener(
        "pushPermissionRequest",
        setPermissionFromWebKit
      );
      window.removeEventListener(
        "pushPermissionState",
        setPermissionFromWebKit
      );
      window.removeEventListener("pushToken", setTokenFromWebKit);
      window.removeEventListener("pushNotification", onMessageFromWebKit);
    };
  }, [
    onMessageFromWebKit,
    requestSupport,
    setPermissionFromWebKit,
    setTokenFromWebKit,
  ]);

  useEffect(() => {
    if (usuario && isSupported && permission == "default") {
      show(true);
    }
  }, [isSupported, usuario, permission, show]);

  useEffect(() => {
    if (permission == "granted" && !token) requestPushToken();
  }, [permission, requestPushToken, token]);

  useEffect(() => {
    if (permission == "granted" && token && usuario) {
      registrarPushUsuario(usuario, token);
    }
  }, [token, permission, usuario, registrarPushUsuario]);

  return (
    <PushNotificationContext.Provider
      value={{
        isSupported: isSupported || false,
        permission: permission || "default",
        requestPermission,
        requestPushToken,
        token,
        lastNotification,
        dispositivo,
      }}
    >
      <RenderDialog />
      {children}
    </PushNotificationContext.Provider>
  );
};
